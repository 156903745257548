import { Spinner, SpinnerSize } from "@fluentui/react"

export interface SpinnerWithTextProps {
    text: string;
    size: SpinnerSize;
}

export const SpinnerWithText: React.FC<SpinnerWithTextProps> = (props) => {
    return (
        <div>
            <Spinner size={props.size} label={props.text} />
        </div>
    )
}

