import axios from "axios";
import { IAdvancedFiltersDto } from "../models/IAdvancedChecklistFiltersDto";
import { IAdvancedFiltersRequest } from "../models/IAdvancedChecklistFiltersRequest";
import { InspectionDetail } from "../models/inspectionDetails";
import { IInspectionSearchFilters } from "../models/inspectionSearchFilters";
import { IInspectionSearchResults } from "../models/inspectionSearchResults";
import { acquireAccessToken } from "../security/msalInstanceHelper";
import { apiBasePath } from "../utils/helpers";

export class InspectionService {
  async searchInspections(inspectionFilters: IInspectionSearchFilters) {
    const config = {
      headers: { Authorization: `Bearer ${await acquireAccessToken()}` },
    };
    const response = await axios.post<IInspectionSearchResults>(
      `${apiBasePath}/api/Inspections/search`,
      inspectionFilters,
      config
    );
    return response;
  }

  async getInspectionDetails(inspectionId: string) {
    const config = {
      headers: { Authorization: `Bearer ${await acquireAccessToken()}` },
    };
    const response = await axios.get<InspectionDetail>(
      `${apiBasePath}/api/Inspections/${inspectionId}/details`,
      config
    );
    return response;
  }

  async getAdvancedFilters(selectedFilters: IAdvancedFiltersRequest) {
    const config = {
      headers: { Authorization: `Bearer ${await acquireAccessToken()}` },
    };
    const response = await axios.post<IAdvancedFiltersDto>(
      `${apiBasePath}/api/Inspections/filters`,
      selectedFilters,
      config
    );
    return response;
  }
}

export default new InspectionService();
