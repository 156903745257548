import { Configuration } from "@azure/msal-browser";
import { listenerCount } from "process";

export const msalConfig: Configuration = {
    auth: {
      clientId: process.env.REACT_APP_AAD_CLIENT_ID!,
      authority: process.env.REACT_APP_AAD_AUTHORITY,
      redirectUri: process.env.REACT_APP_AAD_REDIRECT_URI,
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
  };





  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
    scopes: process.env.REACT_APP_AAD_SCOPES?.split(",") || []
  };
