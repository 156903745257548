import { Transcription } from "../../../../models/transcription";
import { notifyPlayer } from "../../../../utils/notifyPlayer"
import { DetailsList, DetailsListLayoutMode, IColumn, initializeIcons, SearchBox, SelectionMode } from "@fluentui/react";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { getTranscriptionColumns } from "../../../../utils/DetailsListColums";
import Highlighter from "react-highlight-words";


interface ITranscriptionProps {
    iframeRef: React.RefObject<HTMLIFrameElement>,
    data: Transcription[]
    currentItemIndex: number | undefined
}

export const TranscriptionView: React.FC<ITranscriptionProps> = (props) => {

    const [transcriptionFilter, setTranscriptionFilter] = useState("");
    const history = useHistory();

    useEffect(() => {
        initializeIcons();
        const params = new URLSearchParams(window.location.search);
        let parameterName = "text";
        let paramValue = params.get(parameterName);
        setTranscriptionFilter(paramValue || "");
        if (paramValue) {
            params.set(parameterName, "");
            history.replace({
                ...window.location,
                search: decodeURIComponent(params.toString())
            });
        }
    }, [history]);

    const searchBoxRef = useRef<HTMLInputElement>(null);

    const itemClicked = (startAt: number) => {
        notifyPlayer(startAt, props.iframeRef);
    }

    const getFilteredTranscriptions = () => {
        const filteredItems = (props.data || []).filter(item =>
            item && item.text?.toLowerCase()?.split(" ").some(itemTextWord =>
                itemTextWord
                && transcriptionFilter?.toLowerCase().split(" ").some(filterTerm =>
                    filterTerm
                    && itemTextWord.includes(filterTerm)
                )
            )
        );
        if (filteredItems.length > 0) {
            return filteredItems;
        }
        else {
            return props.data;
        }
    }

    const renderItemColumn = (item: Transcription, index: number | undefined, column: IColumn | undefined) => {
        if (column?.key === "text") {
            return (
                index === props.currentItemIndex ?
                    <Highlighter
                        highlightStyle={{ fontWeight: "bold", padding: "0", backgroundColor: "white" }}
                        searchWords={[transcriptionFilter]}
                        autoEscape={true}
                        textToHighlight={item.text}
                        unhighlightStyle={{ fontWeight: "bold", padding: "0" }}

                    />
                    :
                    <Highlighter
                        highlightStyle={{ fontWeight: "bold", padding: "0", backgroundColor: "white" }}
                        searchWords={[transcriptionFilter]}
                        autoEscape={true}
                        textToHighlight={item.text}
                    />
            )





        }

    }

    return (
        <>
            <br></br>
            <SearchBox ref={searchBoxRef} placeholder="Filter transcriptions"
                onChange={(_, newValue) => {
                    if (newValue !== undefined)
                        setTranscriptionFilter(newValue!)
                }
                }
                value={transcriptionFilter}
                onClear={(_) => { setTranscriptionFilter(""); }}
            />

            <DetailsList
                items={getFilteredTranscriptions()}
                columns={getTranscriptionColumns()}
                selectionMode={SelectionMode.none}
                className="pointerMouse"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                selectionPreservedOnEmptyClick={true}
                onActiveItemChanged={(item: Transcription) => itemClicked(item.startAt)}
                enterModalSelectionOnTouch={true}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="select row"
                onRenderItemColumn={(item, index, column) => renderItemColumn(item, index, column)}

            />
        </>
    )
}
