import { DetailsList, DetailsListLayoutMode, IColumn, IGroup, SearchBox, SelectionMode, TooltipHost, TooltipOverflowMode } from "@fluentui/react"
import { useRef, useState } from "react"
import Highlighter from "react-highlight-words"
import { CheckListQuestion, CheckListSection } from "../../../../models/checkList"
import { getChecklistQuestionsColumns } from "../../../../utils/DetailsListColums"
import { notifyPlayer } from "../../../../utils/notifyPlayer"

interface IChecklistProps {
    iframeRef: React.RefObject<HTMLIFrameElement>,
    data: CheckListSection[]
    currentItemIndex: number[]
}

export const SectionsView: React.FC<IChecklistProps> = (props: IChecklistProps) => {
    const [questionsFilter, setQuestionsFilter] = useState("");
    const searchBoxRef = useRef<HTMLInputElement>(null);

    const itemClicked = (startAt: number) => {
        notifyPlayer(startAt, props.iframeRef);
    }

    const getGroupedQuestions = () => {
        const groups: IGroup[] = [];

        let currentIndex = 0;

        props.data.forEach(section => {
            let currentSectionFilteredQuestions = getSectionFilteredQuestionsCount(section);
            let group: IGroup = {
                key: section.sectionId,
                name: section.name ? section.name : section.sectionId,
                level: 0,
                startIndex: currentIndex,
                count: currentSectionFilteredQuestions
            };
            groups.push(group);

            currentIndex += currentSectionFilteredQuestions;
        })

        return groups;
    }

    const getAllFilteredQuestions = () => {
        const filteredItems: CheckListQuestion[] = [];
        if (props.data.length === 0) {
            return [];
        }
        props.data.forEach(section => {
            section && section.questions.forEach(question => {
                if (question && question.question?.toLowerCase()?.includes(questionsFilter?.toLowerCase())) {
                    filteredItems.push(question);
                }
            }
            )
        });

        if (filteredItems.length > 0) {
            return filteredItems;
        }
        else {
            const allQuestions: CheckListQuestion[] = [];
            props.data.forEach(section => section.questions.forEach(q => allQuestions.push(q)));
            return allQuestions;
        }
    }

    const getSectionFilteredQuestionsCount = (section: CheckListSection) => {
        let filteredItems = 0;

        if (!questionsFilter?.toLowerCase()) {
            return section.questions.length;
        }

        if (section) {
            section.questions.forEach(question => {
                if (question && question.question?.toLowerCase()?.includes(questionsFilter?.toLowerCase())) {
                    filteredItems++;
                }
            })
        }

        return filteredItems;
    }

    const renderItemColumn = (item: CheckListQuestion, index: number | undefined, column: IColumn | undefined) => {
        if (column?.key === "question" && index !== undefined) {
            return (
                props.currentItemIndex.indexOf(index) >= 0 ?
                    <TooltipHost
                        content={item.question}
                        overflowMode={TooltipOverflowMode.Parent}
                    >

                        <Highlighter
                            highlightStyle={{ fontWeight: "bold", padding: "0", backgroundColor: "white" }}
                            searchWords={[questionsFilter]}
                            autoEscape={true}
                            textToHighlight={item.question}
                            unhighlightStyle={{ fontWeight: "bold", padding: "0" }}

                        />
                    </TooltipHost>
                    :
                    <TooltipHost
                        content={item.question}
                        overflowMode={TooltipOverflowMode.Parent}
                    >

                        <Highlighter
                            highlightStyle={{ fontWeight: "bold", padding: "0", backgroundColor: "white" }}
                            searchWords={[questionsFilter]}
                            autoEscape={true}
                            textToHighlight={item.question}
                        />
                    </TooltipHost>
            )
        }
    }

    return (
        <>
            <br></br>
            <SearchBox ref={searchBoxRef} placeholder="Filter questions"
                onChange={(_, newValue) => {
                    if (newValue !== undefined)
                        setQuestionsFilter(newValue!)
                }
                }
                onClear={(_) => setQuestionsFilter("")}
                value={questionsFilter}
            />
            <br></br>

            <DetailsList
                items={getAllFilteredQuestions()}
                groups={getGroupedQuestions()}
                columns={getChecklistQuestionsColumns()}
                selectionMode={SelectionMode.none}
                className="pointerMouse"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                selectionPreservedOnEmptyClick={true}
                onActiveItemChanged={(item: CheckListQuestion) => itemClicked(item.checkedAt)}
                enterModalSelectionOnTouch={true}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="select row"
                onRenderItemColumn={(item, index, column) => renderItemColumn(item, index, column)}
                compact={true}
            />
        </>
    )
}
