import { ITag, TagPicker } from "@fluentui/react";
import { useTranslation } from "react-i18next";

export interface IFacetTagPickerProps {
  disabled: boolean;
  onChange(items: ITag[] | undefined): void;
  label: string;
  selectedItems: ITag[];
  options: ITag[];
}

export const FacetTagPicker: React.FunctionComponent<IFacetTagPickerProps> = (
  props: IFacetTagPickerProps
) => {
  /**
   * Based on an input string, filters and returns an array of tags not yet selected
   *
   * @param filter a string used to filter tags elements
   * @param selectedItems current tags selected
   * @returns array of matching tags
   */
  const filterSuggestedNotSelectedTags = (
    filter: string,
    selectedItems?: ITag[] | undefined
  ): ITag[] => {
    if (!filter) {
      return props.options;
    }

    return filter
      ? props.options.filter(
          (tag) =>
            tag.name.toLowerCase().includes(filter.toLowerCase()) &&
            !listContainsTagList(tag, selectedItems)
        )
      : [];
  };
  /**
   * Checks whatever a tag is in a tag's array
   *
   * @param tag
   * @param tagList
   * @returns
   */

  const listContainsTagList = (tag: ITag, tagList?: ITag[]) => {
    if (!tagList || !tagList.length || tagList.length === 0) {
      return false;
    }
    return tagList.some((compareTag) => compareTag.key === tag.key);
  };

  /**
   *
   * When user click on an empty tagpicker, returns a list of all selectable tags
   *
   * @param selectedItems
   * @returns
   */
  const _onEmptyInputFocus = (selectedItems?: ITag[]): ITag[] => {
    console.log("");
    return props.options
      .filter((tag) => !listContainsTagList(tag, selectedItems))
      .slice(0, 100);
  };

  const { t } = useTranslation();

  return (
    <div className="row">
      <div className="col-lg" style={{ paddingLeft: "0px" }}>
        <label htmlFor="" className="ms-Label root-65">
          {props.label}
        </label>
        <TagPicker
          styles={{ root: { width: "80%" } }}
          removeButtonAriaLabel="Remove"
          selectedItems={props.selectedItems}
          onResolveSuggestions={(filter, selectedItems) =>
            filterSuggestedNotSelectedTags(filter, selectedItems)
          }
          getTextFromItem={(item) => item.name}
          itemLimit={1}
          disabled={props.disabled}
          onChange={(items) => props.onChange(items)}
          onEmptyInputFocus={(selectedItems) =>
            _onEmptyInputFocus(selectedItems)
          }
          onEmptyResolveSuggestions={(selectedItems) =>
            _onEmptyInputFocus(selectedItems)
          }
          pickerSuggestionsProps={{
            suggestionsHeaderText: t("values"),
            noResultsFoundText: t("nothing_found"),
          }}
        />
      </div>
    </div>
  );
};
