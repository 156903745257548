import { Persona, PersonaSize } from "@fluentui/react"
import { useTranslation } from "react-i18next";

interface IVideoInspectionDetailsProps {
    location: string;
    remoteInspector: string;
    inspectionDate?: string;
    onfieldEngineer: string;
}


export const VideoInspectionDetails: React.FunctionComponent<IVideoInspectionDetailsProps> = (props) => {
    const { t } = useTranslation();

    return (
        <div style={{ display: "flex", paddingTop: "5vh" }}>
            <Persona
                text={props.onfieldEngineer}
                secondaryText={t("onFieldEngineer")}
                size={PersonaSize.size72}
                hidePersonaDetails={false}
                style={{ paddingRight: "50px" }}
            />

            <Persona
                text={props.remoteInspector}
                secondaryText={t("inspector")}
                size={PersonaSize.size72}
                hidePersonaDetails={false}
            />

            <Persona
                text={props.location}
                secondaryText={t("location")}
                size={PersonaSize.size72}
                hidePersonaDetails={false}
                imageUrl="https://www.indivstock.com/static34/preview2/stock-vector-construction-site-icon-illustrated-in-vector-on-white-background-495840.jpg"
            />
        </div>
    )
}