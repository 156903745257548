import { IColumn } from "@fluentui/react";
import { CheckListQuestion } from "../models/checkList";
import { Transcription } from "../models/transcription";
import { secondsToDateString } from "./helpers";
import i18n from "../i18n/config";

export const getTranscriptionColumns = (): IColumn[] => {
  return [
    {
      key: "startAt",
      name: i18n.t("timestamp"),
      fieldName: "Timestamp",
      minWidth: 70,
      maxWidth: 70,
      isRowHeader: true,
      isResizable: true,
      isSorted: true,
      isSortedDescending: false,
      sortAscendingAriaLabel: "Sorted A to Z",
      sortDescendingAriaLabel: "Sorted Z to A",
      data: "string",
      isPadded: true,
      onRender: (item: Transcription) => (
        <span className="badge badge-secondary" style={{ marginRight: '10px' }}>{`${secondsToDateString(item.startAt)}`}</span>
      ),
    },
    {
      key: "text",
      name: i18n.t("transcription"),
      fieldName: "text",
      minWidth: 150,
      maxWidth: 350,
      isRowHeader: true,
      isResizable: true,
      isSorted: true,
      isSortedDescending: false,
      sortAscendingAriaLabel: "Sorted A to Z",
      sortDescendingAriaLabel: "Sorted Z to A",
      data: "string",
      isPadded: true,
    }];
}

export const getChecklistQuestionsColumns = (): IColumn[] => {
  return [
    {
      key: "checkedAt",
      name: i18n.t("checkedAt"),
      fieldName: "checkedAt",
      minWidth: 70,
      maxWidth: 70,
      isRowHeader: true,
      isResizable: true,
      isSorted: true,
      isSortedDescending: false,
      sortAscendingAriaLabel: "Sorted A to Z",
      sortDescendingAriaLabel: "Sorted Z to A",
      data: "string",
      isPadded: true,
      onRender: (item: CheckListQuestion) => (
        <span className="badge badge-secondary" style={{ marginRight: '10px' }}>{`${secondsToDateString(item.checkedAt)}`}</span>
      ),
    },
    {
      key: "answer",
      name: i18n.t("answer"),
      fieldName: "answer",
      minWidth: 70,
      maxWidth: 70,
      isRowHeader: true,
      isResizable: true,
      isSorted: true,
      isSortedDescending: false,
      sortAscendingAriaLabel: "Sorted A to Z",
      sortDescendingAriaLabel: "Sorted Z to A",
      data: "string",
      isPadded: true,
      onRender: (item: CheckListQuestion) => (
       (item.answer === "Regolare" ?
       <span className="badge badge-success" style={{ marginRight: '10px' }}>{`${(item.answer)}`}</span> : item.answer === "Irregolare" ?
       <span className="badge badge-danger" style={{ marginRight: '10px' }}>{`${(item.answer)}`}</span> :  <span className="badge badge-primary" style={{ marginRight: '10px' }}>{`${(item.answer)}`}</span> 
      
      )),
    },
    {
      key: "question",
      name: i18n.t("question"),
      fieldName: "question",
      minWidth: 150,
      maxWidth: 350,
      isRowHeader: true,
      isResizable: true,
      isSorted: true,
      isSortedDescending: false,
      sortAscendingAriaLabel: "Sorted A to Z",
      sortDescendingAriaLabel: "Sorted Z to A",
      data: "string",
      isPadded: true,
    }];
}