import { FontSizes, FontWeights } from "@fluentui/react";
import { useTranslation } from "react-i18next";

export const SearchHeader: React.FunctionComponent = () => {
    const { t } = useTranslation();

    return (
        <>
        <span style={{ fontSize: FontSizes.size32, fontWeight: FontWeights.bold as number }}>{t('extract_insights')}</span>
        <br></br>
        <span style={{ fontSize: FontSizes.size32, fontWeight: FontWeights.regular as number }}>{t('search_any_text')}</span>
        </>
    )
}