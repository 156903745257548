import { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom"
import { FontSizes, FontWeights, Pivot, PivotItem, Spinner, SpinnerSize } from "@fluentui/react"
import { VideoInspectionDetails } from "./components/VideoInspectionDetails";
import { TranscriptionView } from "./components/Transcription/TranscriptionView";
import { ChatView } from "./components/Chat/ChatView";
import { useQuery } from "react-query";
import InspectionService from "../../services/InspectionService";
import { useTranslation } from "react-i18next";
import { ChecklistView } from "./components/Checklist/ChecklistView";

type InspectionDetailsParams = {
  id: string;
};

export const InspectionDetails: React.FunctionComponent = () => {
  const { id } = useParams<InspectionDetailsParams>();
  const videoIframe = useRef<HTMLIFrameElement>(null);
  const { t } = useTranslation();
  const [isFetchingDetails, setIsFetchingDetails] = useState(true);

  const { data } = useQuery(id, async () => {
    const newLocal = await InspectionService.getInspectionDetails(id);
    setIsFetchingDetails(false);
    return newLocal;
  }, {
    refetchOnWindowFocus: false,
  });
  const [currentItemIndex, setCurrentItemIndex] = useState<number | undefined>();
  const [currentChecklistItemIndex, setCurrentChecklistItemIndex] = useState<number[]>([]);
  const history = useHistory();


  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    let parameterName = "q";
    let paramValue = params.get(parameterName);
    if (paramValue) {
      params.set(parameterName, "");
      history.replace({
        ...window.location,
        search: decodeURIComponent(params.toString())
      });
    }
    let vv: number[] = [];
    paramValue?.split(",").forEach(value => {

      vv.push(parseInt(value));

    })
    setCurrentChecklistItemIndex(vv);


  }, [history]);


  // Jump to specific time from mesage payload
  const highlightItem = (evt: any) => {
    if (!evt || !data?.data) {
      return;
    }
    var origin = evt.origin || evt.originalEvent.origin;

    // Validate that event comes from videoindexer domain.
    if ((origin.indexOf(".videoindexer.ai") !== -1) && (evt.data.currentTime !== undefined)) {
      // Highlight relative item.
      (data.data.transcription || []).forEach((item, index) => {
        if (item) {
          let currentMoreThanStart = item.startAt <= evt.data.currentTime;
          let currentLessThanFinish = item.endAt >= evt.data.currentTime;

          if (currentMoreThanStart && currentLessThanFinish) {
            setCurrentItemIndex(index);
            return;
          }
        }
      });
    }
  }

  const clearMessageEvent = () => {
    if (window.removeEventListener) {
      window.removeEventListener("message", highlightItem);
    }
  }

  // Listen to message events from breakdown iframes
  window.addEventListener("message", highlightItem, false);

  // Clear the event if window unloads
  window.onunload = clearMessageEvent;

  return isFetchingDetails ? (
    <div style={{
      paddingTop: "5vh",
    }}>
      <Spinner
        size={SpinnerSize.large}
        label={t('loading_details')}
      />
    </div>
  ) : (
    <>
      <div className="row" >
        <div className="col-sm-7" >
          <span style={{ fontSize: FontSizes.size32, fontWeight: FontWeights.regular as number }}>{t("inspection_insights")}</span> <br></br>

          <br></br>
          <iframe title="viPlayer" ref={videoIframe} id="viPlayer"
            style={
              {
                minWidth: "55vw",
                minHeight: "55vh"
              }
            }
            src={data?.data.videoUrl + '&autoplay=false'}>
          </iframe>

          <div style={
            {
              minWidth: "55vw",
              minHeight: "3vh"
            }
          }>
            <VideoInspectionDetails
              inspectionDate={data?.data.details.date || ""}
              location={data?.data.details.location || ""}
              remoteInspector={data?.data.details.inspector || ""}
              onfieldEngineer={data?.data.details.onFieldEngineer || ""}
            />
          </div>
        </div>
        <div className="col-sm-5" style={{
          paddingTop: "2vh",
          maxHeight: "85vh",
          overflowX: "auto",
          overflowY: "auto",
          position: "relative"
        }}>
          <Pivot>
            <PivotItem headerText={t("transcriptions")}
              headerButtonProps={{
                'data-order': 1,
                'data-title': 'Transcriptions',
              }}>
              <TranscriptionView
                iframeRef={videoIframe}
                data={data?.data.transcription || []}
                currentItemIndex={currentItemIndex}
              />
            </PivotItem>
            <PivotItem headerText="Checklists">
              <ChecklistView
                data={data?.data.details.checklists || []}
                iframeRef={videoIframe}
                currentItemIndex={currentChecklistItemIndex}
              />
            </PivotItem>
            <PivotItem headerText={t("chat_messages")}>
              <ChatView
                data={data?.data.details.chatMessages || []}
                onfieldEngineerName={data?.data.details.onFieldEngineer || ""}
                remoteInspectorName={data?.data.details.inspector || ""}
              />
            </PivotItem>
          </Pivot>
        </div>
      </div>
    </>
  )
}
