import React from 'react';
import './App.css';
import './i18n/config';
import { Route, Switch } from 'react-router-dom';
import { NavBar } from './components/NavBar';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { SignInButton } from './components/SignInButton';
import { Search } from './pages/Search/Search';
import { InspectionDetails } from './pages/InspectionDetails/InspectionDetails';
import { NoRouteMatch } from './components/NoRouteMatch';
import { FontSizes, FontWeights } from '@fluentui/react';

export const App: React.FunctionComponent = () => {
  return (

    <div className="container-fluid">

      <AuthenticatedTemplate>
        <NavBar />
        <Switch>
          <Route exact path="/" sensitive>
            <Search />
          </Route>
          <Route path="/inspection/:id">
            <InspectionDetails />
          </Route>
          <Route path="*">
            <NoRouteMatch />
          </Route>
        </Switch>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <NavBar />
        <div className="center">
          <span style={{ fontSize: FontSizes.size32, fontWeight: FontWeights.regular as number }}>You are not signed in! Please sign in.</span>
          <br></br>
          <SignInButton />
        </div>

      </UnauthenticatedTemplate>

    </div>
  );

};
