import { Persona, PersonaSize } from "@fluentui/react"
import "./ChatBubble.css"

export enum ChatBubbleOrientation {
    LEFT,
    RIGHT
}

export interface IChatBubbleProps {
    position: ChatBubbleOrientation
    text: string
    chatUserName: string
}

export const ChatBubble: React.FunctionComponent<IChatBubbleProps> = (props: IChatBubbleProps) => {




    if (props.position === ChatBubbleOrientation.LEFT) {
        return (
            <div className="msg left-msg">
                <div
                    className="msg-img"
                >

                    <Persona

                        text={props.chatUserName}
                        size={PersonaSize.size56}
                        hidePersonaDetails={false}
                        style={{ paddingRight: "50px" }}
                    />
                </div>

                <div className="msg-bubble">
                    <div className="msg-info">
                        <div className="msg-info-name">{props.chatUserName}</div>
                        <div className="msg-info-time"></div>
                    </div>

                    <div className="msg-text">
                        {props.text}
                    </div>
                </div>
            </div>)

    }
    else {
        return (
            <div className="msg right-msg">
                <div
                    className="msg-img"
                >
                    <Persona

                        text={props.chatUserName}
                        size={PersonaSize.size56}
                        hidePersonaDetails={false}
                        style={{ paddingRight: "50px" }}
                    />
                </div>

                <div className="msg-bubble">
                    <div className="msg-info">
                        <div className="msg-info-name">{props.chatUserName}</div>
                        <div className="msg-info-time"></div>
                    </div>

                    <div className="msg-text">
                        {props.text}
                    </div>
                </div>
            </div>)
    }

}