import {
  DetailsList,
  DetailsListLayoutMode,
  FontIcon,
  IColumn,
  SelectionMode,
  SpinnerSize,
} from "@fluentui/react";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { SpinnerWithText } from "../../components/SpinnerWithText";
import { VsSearchBox } from "../../components/VsSearchBox";
import { CheckList } from "../../models/checkList";
import { Inspection } from "../../models/inspection";
import { IInspectionSearchFilters } from "../../models/inspectionSearchFilters";
import InspectionService from "../../services/InspectionService";
import { SearchHeader } from "./components/SearchHeader";
import { useTranslation } from 'react-i18next';
import { IInspectionSearchResults } from "../../models/inspectionSearchResults";

export const Search: React.FC = () => {
  const history = useHistory();
  const [searchResults, setSearchResults] = useState<(Inspection)[]>([]);
  const [textSearch, setTextSearch] = useState("");
  const [isFetchingData, setIsFetchingData] = useState<boolean | undefined>(undefined);
  const [checklistQuestonIndexes, setChecklistQuestonIndexes] = useState<number[]>([]);
  const { t } = useTranslation();
  const [searchFilters, setSearchFilters] = useState<IInspectionSearchFilters>({});
  const [canStillSearch, setCanStillSearch] = useState<boolean>(false);

  const searchVideoInspectionMutation = useMutation({
    mutationFn: InspectionService.searchInspections,
    retry: 1,
    retryDelay: attempt => Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000)
  });

  const search = (filtersParam: IInspectionSearchFilters) => {
    if (searchVideoInspectionMutation.isLoading || isFetchingData) {
      return;
    }

    setIsFetchingData(true);
    searchVideoInspectionMutation.mutate(filtersParam, {
      onError: (error: any) => {
        setIsFetchingData(false);
        console.log(error);
        setSearchResults([]);
      },
      onSuccess: (data: { data: IInspectionSearchResults }) => {
        // FIXME Could be more efficient???

        setIsFetchingData(false);
        let indexes: number[] = [];
        const nothingToSkip = !filtersParam.skip || filtersParam.skip === 0;
        let results: Inspection[] = nothingToSkip ? [] : [...searchResults];

        data.data.inspections?.forEach((inspection: Inspection) => {
          if (inspection) {
            let alreadyPresentInspection = results.find(x => x && x.inspectionId === inspection.inspectionId);
            if (nothingToSkip || !alreadyPresentInspection) {
              results.push(inspection);
            }
          }

          inspection.checklists?.forEach((checklist: CheckList) => {
            let allQuestionsInChecklist = flatChecklistQuestions(checklist);
            allQuestionsInChecklist.forEach((question, index) => {
              filtersParam.checklistFilters?.checklistSectionFilters?.checklistQuestionFilters?.questions?.forEach(filterQuestion => {
                if (filterQuestion === question) {
                  indexes.push(index);
                }
              })
            })
          })
        })
        setChecklistQuestonIndexes(indexes);

        if (data.data.canStillSearch) {
          let newSearchFilters = { ...filtersParam };
          newSearchFilters.skip! += newSearchFilters.size!;
          setSearchFilters(newSearchFilters);
        }

        setCanStillSearch(data.data.canStillSearch!)
        setSearchResults(results);
      }
    });

    setTextSearch(filtersParam.text || "");
  };

  const flatChecklistQuestions = (checklist: CheckList): string[] => {
    let questions: string[] = [];
    checklist.sections?.forEach(section => {
      section.questions.forEach(question => {
        questions.push(question.question);
      })
    })
    return questions;
  }

  const _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    let newSearchFilters = searchFilters;
    newSearchFilters.orderBy = column.key.toString();
    newSearchFilters.orderBy += ' ';
    newSearchFilters.orderBy += currColumn.isSortedDescending ? 'desc' : 'asc';
    newSearchFilters.skip = 0;
    newSearchFilters.size = 20;
    setSearchResults([]);
    setSearchFilters(newSearchFilters);
    setColumns(newColumns);
    search(newSearchFilters);
  };

  const getSearchResultsColumns = (): IColumn[] => {
    return [
      //// Just for debugging purposes, to check if ids are unique in the grid
      // {
      //   key: "inspectionId",
      //   name: "inspectionId",
      //   fieldName: "inspectionId",
      //   onColumnClick: _onColumnClick,
      //   minWidth: 150,
      //   maxWidth: 250,
      //   isRowHeader: true,
      //   isResizable: true,
      //   isSorted: false,
      //   isSortedDescending: false,
      //   sortAscendingAriaLabel: "Sorted A to Z",
      //   sortDescendingAriaLabel: "Sorted Z to A",
      //   data: "string",
      //   isPadded: true,
      // },
      {
        key: "icon",
        name: "icon",
        iconName: "",
        isIconOnly: true,
        fieldName: "icon",
        minWidth: 32,
        maxWidth: 32,
        onRender: (item: Inspection) => (
          <FontIcon
            style={{ color: "#007bff" }}
            aria-label="MSNVideosSolid"
            iconName="MSNVideosSolid"
          />
        ),
      },
      {
        key: "date",
        name: t("date"),
        fieldName: "date",
        minWidth: 100,
        onColumnClick: _onColumnClick,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        isSorted: true,
        isSortedDescending: true,
        sortAscendingAriaLabel: "Sorted older to newer",
        sortDescendingAriaLabel: "Sorted newer to older",
        isPadded: true,
        onRender: (item: Inspection) => (
          <span>{(item && item.date) ? new Date(item.date).toLocaleString() : new Date().toLocaleString()}</span>
        )
      },
      {
        key: "inspector",
        name: t("inspector"),
        fieldName: "inspector",
        onColumnClick: _onColumnClick,
        minWidth: 150,
        maxWidth: 250,
        isRowHeader: true,
        isResizable: true,
        isSorted: false,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        data: "string",
        isPadded: true,
      },
      {
        key: "onFieldEngineer",
        name: t("onFieldEngineer"),
        fieldName: "onFieldEngineer",
        minWidth: 150,
        maxWidth: 250,
        onColumnClick: _onColumnClick,
        isRowHeader: true,
        isResizable: true,
        isSorted: false,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        data: "string",
        isPadded: true,
      },
      {
        key: "location",
        name: t("location"),
        fieldName: "location",
        onColumnClick: _onColumnClick,
        minWidth: 100,
        maxWidth: 350,
        isRowHeader: true,
        isResizable: true,
        isSorted: false,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        data: "string",
        isPadded: true,
      }
    ];
  }

  const [columns, setColumns] = useState<IColumn[]>(getSearchResultsColumns());

  //Initialization of search results
  useEffect(() => {
    var filters = {
      size: 20,
      skip: 0
    };
    setSearchFilters(filters);
    InspectionService.searchInspections(filters)
      .then(response => {
        setIsFetchingData(false);

        let results: (Inspection)[] = response.data.inspections!;

        setCanStillSearch(response.data.canStillSearch!)
        setSearchResults(results);
        filters.skip += filters.size;
      })
      .catch(reason => {
        console.log(reason);
        setIsFetchingData(false);
      })
  }, []);

  return (
    <>
      <div className="row justify-content-md-left">
        <div className="col-lg-12">
          <div className="row justify-content-md-left">
            <div className="col-lg-8 offset-lg-2">
              <SearchHeader />
              <VsSearchBox
                disableSearchButton={isFetchingData}
                onSearchclicked={(searchParameters: IInspectionSearchFilters) => {
                  let mergedFilters = { ...searchParameters };
                  mergedFilters.orderBy = searchFilters.orderBy;
                  mergedFilters.size = searchFilters.size;
                  setSearchFilters(mergedFilters);
                  search(mergedFilters)
                }}
              />
              <br></br>
            </div></div>
          {isFetchingData === undefined ? (
            <SpinnerWithText
              size={SpinnerSize.large}
              text={t('searching_inspections')}
            />
          ) : (
            <>
              <div className="row justify-content-md-left">
                <div className="col-lg-8 offset-lg-2 detailsListFix" onScroll={(event: any) => {
                  if (!(searchVideoInspectionMutation.isLoading || isFetchingData)
                    && canStillSearch
                    && event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight) {
                    search(searchFilters);
                  }
                }}>
                  {searchResults?.length > 0 && (
                    <DetailsList
                      items={searchResults}
                      columns={columns}
                      selectionMode={SelectionMode.none}
                      className="pointerMouse"
                      layoutMode={DetailsListLayoutMode.justified}
                      isHeaderVisible={true}
                      selectionPreservedOnEmptyClick={true}
                      onActiveItemChanged={(item: Inspection) => history.push(`/inspection/${item.inspectionId}?text=${textSearch}&q=${checklistQuestonIndexes.join()}`)}
                      enterModalSelectionOnTouch={true}
                      ariaLabelForSelectionColumn="Toggle selection"
                      ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                      checkButtonAriaLabel="select row"
                    />
                  )}
                  {
                    isFetchingData ?
                      (
                        <SpinnerWithText
                          size={SpinnerSize.medium}
                          text={t('searching_inspections')}
                        />
                      ) :
                      (null)
                  }
                </div>
              </div>
            </>
          )}
        </div>
      </div>

    </>
  );
};




