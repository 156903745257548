import {
  ComboBox,
  DatePicker,
  DayOfWeek,
  defaultDatePickerStrings,
  IComboBox,
  IComboBoxOption,
  PrimaryButton,
  SpinnerSize,
  TextField,
} from "@fluentui/react";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { IInspectionSearchFilters } from "../models/inspectionSearchFilters";
import { IInspectionSearchResults } from "../models/inspectionSearchResults";
import InspectionService from "../services/InspectionService";

import { ITag } from "@fluentui/react/lib/Pickers";
import { Toggle } from "@fluentui/react/lib/Toggle";
import { useBoolean } from "@fluentui/react-hooks";
import { AdvancedFiltersFacetsNames, FacetsNames } from "../utils/constants";
import { FacetTagPicker } from "./FacetTagPicker";
import { useTranslation } from "react-i18next";
import { SpinnerWithText } from "./SpinnerWithText";
import { IAdvancedFiltersRequest } from "../models/IAdvancedChecklistFiltersRequest";

export interface VsSearchBoxProps {
  onSearchclicked(searchParameters: IInspectionSearchFilters): void;
  disableSearchButton?: boolean;
}

export const VsSearchBox: React.FunctionComponent<VsSearchBoxProps> = (
  props: VsSearchBoxProps
) => {
  /*
   *
   * States declarations
   *
   */
  const { t } = useTranslation();

  const [searchText, setSearchText] = useState("");
  const [onFieldEngineers, setOnFieldEngineers] = useState<string[]>([]);
  const [inspectors, setInspectors] = useState<string[]>([]);
  const [locations, setLocations] = useState<string[]>([]);
  const [countries, setCountries] = useState<string[]>([]);
  const [companyCodes, setCompanyCodes] = useState<string[]>([]);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [checklistNames, setChecklistNames] = useState<string[]>([]);
  const [sectionNames, setSectionNames] = useState<string[]>([]);
  const [questions, setQuestions] = useState<string[]>([]);
  const [answers, setAnswers] = useState<string[]>([]);
  const [showAdvancedSearch, { toggle: toggleIsAdvancedSearchVisible }] =
    useBoolean(false);
  const [isFetchingFacets, setIsFetchingFacets] = useState(true);
  const timePickerStart = React.createRef<IComboBox>();
  const timePickerEnd = React.createRef<IComboBox>();
  const [timeStartSelectedKey, setTimeStartSelectedKey] = React.useState<
    string | undefined
  >("");
  const [timeEndSelectedKey, setTimeEndSelectedKey] = React.useState<
    string | undefined
  >("");
  const getTimeOptions = (): IComboBoxOption[] => {
    let result: IComboBoxOption[] = [
      {
        key: "",
        text: "",
        selected: false,
      },
    ];
    for (let i = 0; i < 24; i++) {
      const hour = i.toString().padStart(2, "0") + ":00";
      const halfHour = i.toString().padStart(2, "0") + ":30";
      result.push({
        key: hour,
        text: hour,
        selected: false,
      });
      result.push({
        key: halfHour,
        text: halfHour,
        selected: false,
      });
    }

    return result;
  };

  useEffect(() => {
    // Update the document title using the browser API
    initializeIcons();
  });

  /**
   * Converts the data from server to combobox format (IComboBoxOption)
   */
  const toIComboBoxOption = (
    items: IInspectionSearchResults,
    facetName: string,
    selected?: boolean
  ): IComboBoxOption[] => {
    let results: IComboBoxOption[] = [];
    for (let key in items.stringValueFacets) {
      if (key === facetName) {
        items.stringValueFacets[key].forEach((item) => {
          results.push({
            key: item.value,
            text: item.value,
            selected: selected,
          });
        });
      }
    }
    return results;
  };

  /**
   * Converts the facets data from server to tagPicker format (ITag)
   *
   * @param items
   * @param facetName
   * @returns
   */
  const IInspectionSearchResultsToITag = (
    items: IInspectionSearchResults,
    facetName: string
  ): ITag[] => {
    let results: ITag[] = [];
    if (!items?.stringValueFacets) {
      return [];
    }

    for (let key in items.stringValueFacets) {
      if (key === facetName) {
        items.stringValueFacets[key].forEach((item) => {
          results.push({
            key: item.value,
            name: item.value,
          });
        });
      }
    }
    return results;
  };

  const setHoursToDateFromText = (date: Date | undefined, text: string) => {
    if (date === undefined) {
      return undefined;
    }
    let newDate = new Date(date);

    if (text && text !== "") {
      var hourMinSecArray = text.split(":").map((x) => {
        return Number(x);
      });

      if (hourMinSecArray.length === 2) {
        newDate.setHours(hourMinSecArray[0], hourMinSecArray[1]);
      } else if (hourMinSecArray.length === 3) {
        newDate.setHours(
          hourMinSecArray[0],
          hourMinSecArray[1],
          hourMinSecArray[2]
        );
      }
    }

    return newDate;
  };

  const stringArrayToITag = (items: string[]): ITag[] => {
    let results: ITag[] = [];

    items.forEach((item) => {
      results.push({
        key: item,
        name: item,
      });
    });
    return results;
  };

  /**
   * Call api to get all the data needed to populate all the combobox
   * and the tagpickers
   */
  const { data } = useQuery(
    "facets",
    async () => {
      var result = await InspectionService.searchInspections({
        facets: [
          FacetsNames.locationFacetName,
          FacetsNames.onFieldEngineerFacetName,
          FacetsNames.inspectorFacetName,
        ],
      });
      setIsFetchingFacets(false);
      return result;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  let filters = {
    fromDate: startDate,
    toDate: endDate,
    inspectors: inspectors,
    onFieldEngineers: onFieldEngineers,
    locations: locations,
    companyCodes: companyCodes,
    countries: countries,
    text: searchText,
    checklistFilters: {
      names: checklistNames,
      checklistSectionFilters: {
        names: sectionNames,
        checklistQuestionFilters: {
          answers: answers,
          questions: questions,
        },
      },
    },
    facets: [AdvancedFiltersFacetsNames.countryFacetName],
    size: 0,
    skip: 0,
  };

  const countriesFacetQuery = useQuery(
    ["countriesFacetQuery"],
    async () => {
      var result = await InspectionService.searchInspections(filters);
      return result;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  let selectedAdvancedFilters: IAdvancedFiltersRequest = {
    country: countries.length > 0 ? countries[0] : "",
    companyCode: companyCodes.length > 0 ? companyCodes[0] : "",
    checklistName: checklistNames.length > 0 ? checklistNames[0] : "",
    section: sectionNames.length > 0 ? sectionNames[0] : "",
    question: questions.length > 0 ? questions[0] : "",
  };

  const advancedFiltersQuery = useQuery(
    ["advancedFiltersQuery", selectedAdvancedFilters],
    async () => {
      var result = await InspectionService.getAdvancedFilters(
        selectedAdvancedFilters
      );
      return result;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const onSearch = () => {
    props.onSearchclicked({
      fromDate: startDate,
      toDate: endDate,
      companyCodes: companyCodes,
      countries: countries,
      inspectors: inspectors,
      onFieldEngineers: onFieldEngineers,
      locations: locations,
      text: searchText,
      checklistFilters: {
        names: checklistNames,
        checklistSectionFilters: {
          names: sectionNames,
          checklistQuestionFilters: {
            answers: answers,
            questions: questions,
          },
        },
      },
      skip: 0,
    });
  };

  const onClear = () => {
    let noFiltersSearch: IInspectionSearchFilters = {
      fromDate: undefined,
      toDate: undefined,
      inspectors: [],
      onFieldEngineers: [],
      locations: [],
      text: "",
      companyCodes: [],
      countries: [],
      checklistFilters: {
        names: [],
        checklistSectionFilters: {
          names: [],
          checklistQuestionFilters: {
            answers: [],
            questions: [],
          },
        },
      },
      size: 20,
      skip: 0,
    };

    setSearchText(noFiltersSearch.text!);
    setOnFieldEngineers(noFiltersSearch.onFieldEngineers!);
    setInspectors(noFiltersSearch.inspectors!);
    setLocations(noFiltersSearch.locations!);
    setStartDate(noFiltersSearch.fromDate);
    setEndDate(noFiltersSearch.toDate);
    setCompanyCodes(noFiltersSearch.companyCodes!);
    setCountries(noFiltersSearch.countries!);
    setSectionNames(
      noFiltersSearch.checklistFilters?.checklistSectionFilters?.names!
    );
    setChecklistNames(noFiltersSearch.checklistFilters?.names!);
    setQuestions(
      noFiltersSearch.checklistFilters?.checklistSectionFilters
        ?.checklistQuestionFilters?.questions!
    );
    setAnswers(
      noFiltersSearch.checklistFilters?.checklistSectionFilters
        ?.checklistQuestionFilters?.answers!
    );
    setTimeStartSelectedKey("");
    setTimeEndSelectedKey("");

    //After clear we also need to do a search
    props.onSearchclicked(noFiltersSearch);
  };

  return isFetchingFacets ? (
    <SpinnerWithText
      size={SpinnerSize.large}
      text={t("loading_inspections_filters")}
    />
  ) : (
    <>
      <div>
        <br />
        <div className="row">
          <div className="col-lg-3" style={{ paddingLeft: "0px" }}>
            <TextField
              label={t("chat_or_transcription_text")}
              value={searchText}
              onChange={(_e?, newValue?) => {
                if (newValue) {
                  setSearchText(newValue);
                } else {
                  setSearchText("");
                }
              }}
            />
          </div>

          <div className="col" style={{ paddingLeft: "0px" }}>
            <ComboBox
              label={t("worksite_location")}
              allowFreeform={false}
              autoComplete="on"
              selectedKey={locations}
              multiSelect={true}
              options={toIComboBoxOption(
                data?.data || {},
                FacetsNames.locationFacetName,
                false
              )}
              onChange={(_e?, val?) => {
                if (
                  val &&
                  val.selected &&
                  val.text &&
                  val.text !== "" &&
                  !locations.includes(val.text)
                ) {
                  locations.push(val.text);
                } else {
                  var index = locations.indexOf(val!.text);
                  if (index !== -1) {
                    locations.splice(index, 1);
                  }
                }
                setLocations(() => [...locations]);
              }}
            />
          </div>
          <div className="col" style={{ paddingLeft: "0px" }}>
            <DatePicker
              firstDayOfWeek={DayOfWeek.Monday}
              allowTextInput={true}
              placeholder="Select a date..."
              ariaLabel="Select a date"
              strings={defaultDatePickerStrings}
              label={t("start_date")}
              value={startDate}
              onSelectDate={(date) => {
                if (date !== null) {
                  setStartDate(
                    setHoursToDateFromText(
                      date,
                      timePickerStart!.current!.selectedOptions[0].text
                    )
                  );
                }
              }}
            />
          </div>
          <div className="col" style={{ paddingLeft: "0px" }}>
            <ComboBox
              componentRef={timePickerStart}
              defaultSelectedKey=""
              label={t("start_date_hour")}
              options={getTimeOptions()}
              selectedKey={timeStartSelectedKey}
              onChange={(_e?, val?) => {
                var date = new Date();
                if (startDate) {
                  date = startDate;
                }
                if (val && val.text && val.text !== "") {
                  setStartDate(setHoursToDateFromText(date, val.text));
                  setTimeStartSelectedKey(val.text);
                }
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col" style={{ paddingLeft: "0px" }}>
            <ComboBox
              label={t("inspector")}
              allowFreeform={false}
              autoComplete="on"
              multiSelect={true}
              options={toIComboBoxOption(
                data?.data || {},
                FacetsNames.inspectorFacetName,
                false
              )}
              selectedKey={inspectors}
              onChange={(_e?, val?) => {
                if (val?.selected && !inspectors.includes(val.text)) {
                  inspectors.push(val.text);
                } else {
                  var index = inspectors.indexOf(val!.text);
                  if (index !== -1) {
                    inspectors.splice(index, 1);
                  }
                }
                setInspectors(() => [...inspectors]);
              }}
            />
          </div>
          <div className="col" style={{ paddingLeft: "0px" }}>
            <ComboBox
              label={t("onFieldEngineer")}
              allowFreeform={false}
              autoComplete="on"
              multiSelect={true}
              selectedKey={onFieldEngineers}
              options={toIComboBoxOption(
                data?.data || {},
                FacetsNames.onFieldEngineerFacetName,
                false
              )}
              onChange={(_e?, val?) => {
                if (val?.selected && !onFieldEngineers.includes(val.text)) {
                  onFieldEngineers.push(val.text);
                } else {
                  var index = onFieldEngineers.indexOf(val!.text);
                  if (index !== -1) {
                    onFieldEngineers.splice(index, 1);
                  }
                }
                setOnFieldEngineers(() => [...onFieldEngineers]);
              }}
            />
          </div>
          <div className="col" style={{ paddingLeft: "0px" }}>
            <DatePicker
              firstDayOfWeek={DayOfWeek.Monday}
              placeholder="Select a date..."
              allowTextInput={true}
              ariaLabel="Select a date"
              strings={defaultDatePickerStrings}
              label={t("end_date")}
              value={endDate}
              onSelectDate={(date) => {
                if (date !== null) {
                  setEndDate(
                    setHoursToDateFromText(
                      date,
                      timePickerEnd!.current!.selectedOptions[0].text
                    )
                  );
                }
              }}
              showCloseButton={true}
            />
          </div>
          <div className="col" style={{ paddingLeft: "0px" }}>
            <ComboBox
              componentRef={timePickerEnd}
              defaultSelectedKey=""
              label={t("end_date_hour")}
              options={getTimeOptions()}
              selectedKey={timeEndSelectedKey}
              onChange={(_e?, val?) => {
                var date = new Date();
                if (endDate) {
                  date = endDate;
                } else if (val && val.text && val.text !== "") {
                  setEndDate(setHoursToDateFromText(date, val.text));
                  setTimeEndSelectedKey(val.text);
                }
              }}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-lg-8" style={{ paddingLeft: "0px" }}>
            <Toggle
              label={t("advanced_search")}
              inlineLabel
              checked={showAdvancedSearch}
              onChange={toggleIsAdvancedSearchVisible}
            />
          </div>
        </div>

        {showAdvancedSearch && (
          <>
            {countriesFacetQuery.isFetching ? (
              <SpinnerWithText
                size={SpinnerSize.large}
                text={t("loading_inspections_filters")}
              />
            ) : (
              <>
                {/* countries */}
                <FacetTagPicker
                  label={t("countries")}
                  options={IInspectionSearchResultsToITag(
                    countriesFacetQuery.data?.data!,
                    AdvancedFiltersFacetsNames.countryFacetName
                  )}
                  selectedItems={stringArrayToITag(countries)}
                  disabled={countriesFacetQuery.isFetching}
                  onChange={(items) => {
                    let newSelection: string[] = [];
                    items?.forEach((item) => {
                      newSelection.push(item.name);
                    });
                    setCountries(() => [...newSelection]);
                  }}
                />

                {/* companyCodes */}
                <FacetTagPicker
                  label={t("company_codes")}
                  options={
                    countries.length === 0 ||
                      advancedFiltersQuery.isFetching
                      ? []
                      : stringArrayToITag(
                        advancedFiltersQuery.data?.data?.companyCodes!
                      )
                  }
                  selectedItems={stringArrayToITag(companyCodes)}
                  disabled={countries.length === 0}
                  onChange={(items) => {
                    let newSelection: string[] = [];
                    items?.forEach((item) => {
                      newSelection.push(item.name);
                    });
                    setCompanyCodes(() => [...newSelection]);
                  }}
                />

                {/* checklist names */}
                <FacetTagPicker
                  label={t("checklist_names")}
                  options={
                    companyCodes.length === 0 ||
                      advancedFiltersQuery.isFetching
                      ? []
                      : stringArrayToITag(
                        advancedFiltersQuery.data?.data?.checklistNames!
                      )
                  }
                  selectedItems={stringArrayToITag(checklistNames)}
                  disabled={companyCodes.length === 0}
                  onChange={(items) => {
                    let newSelection: string[] = [];
                    items?.forEach((item) => {
                      newSelection.push(item.name);
                    });
                    setChecklistNames(() => [...newSelection]);
                  }}
                />

                <FacetTagPicker
                  label={t("checklist_sections")}
                  options={
                    checklistNames.length === 0 ||
                      advancedFiltersQuery.isFetching
                      ? []
                      : stringArrayToITag(
                        advancedFiltersQuery.data?.data?.sections!
                      )
                  }
                  selectedItems={stringArrayToITag(sectionNames)}
                  disabled={checklistNames.length === 0}
                  onChange={(items) => {
                    let newSelection: string[] = [];
                    items?.forEach((item) => {
                      newSelection.push(item.name);
                    });
                    setSectionNames(() => [...newSelection]);
                  }}
                />

                <FacetTagPicker
                  label={t("checklist_questions")}
                  options={
                    sectionNames.length === 0 || advancedFiltersQuery.isFetching
                      ? []
                      : stringArrayToITag(
                        advancedFiltersQuery.data?.data?.questions!
                      )
                  }
                  selectedItems={stringArrayToITag(questions)}
                  disabled={sectionNames.length === 0}
                  onChange={(items) => {
                    let newSelection: string[] = [];
                    items?.forEach((item) => {
                      newSelection.push(item.name);
                      selectedAdvancedFilters.question = item.name;
                    });
                    setQuestions(() => [...newSelection]);
                  }}
                />

                <FacetTagPicker
                  label={t("checklist_answers")}
                  options={
                    questions.length === 0 || advancedFiltersQuery.isFetching
                      ? []
                      : stringArrayToITag(
                        advancedFiltersQuery.data?.data?.answers!
                      )
                  }
                  selectedItems={stringArrayToITag(answers)}
                  disabled={questions.length === 0}
                  onChange={(items) => {
                    let newSelection: string[] = [];
                    items?.forEach((item) => {
                      newSelection.push(item.name);
                    });
                    setAnswers(() => [...newSelection]);
                  }}
                />
              </>
            )}
          </>
        )}

        <div className="row">
          <div
            className="col-lg-2"
            style={{ paddingLeft: "0px", paddingTop: "20px" }}
          >
            <PrimaryButton
              text={t("search")}
              onClick={(_e) => onSearch()}
              disabled={props.disableSearchButton}
            />
          </div>
          <div
            className="col-lg-2"
            style={{ paddingLeft: "0px", paddingTop: "20px" }}
          >
            <PrimaryButton text={t("clear")} onClick={(_e) => onClear()} />
          </div>
        </div>
      </div>
    </>
  );
};
