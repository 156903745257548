export const notifyPlayer = (time: number,iframeRef: React.RefObject<HTMLIFrameElement>) => {
    let payload = {
        time: time,
        origin: "https://www.videoindexer.ai"
    };

    if ('postMessage' in window) {
        console.log("FIRED!")
        try {
            if (iframeRef?.current?.contentWindow) {
                iframeRef.current.contentWindow.postMessage(payload, payload.origin);
            }
        } catch (error) {
            throw error;
        }
    }
}