import { Pivot, PivotItem } from "@fluentui/react"
import { CheckList } from "../../../../models/checkList"
import { SectionsView } from "./SectionsView"

interface IChecklistViewProps {
    iframeRef: React.RefObject<HTMLIFrameElement>,
    data: CheckList[]
    currentItemIndex: number[]
}

export const ChecklistView: React.FC<IChecklistViewProps> = (props: IChecklistViewProps) => {
    const getTotalQuestionsCount = (checklist: CheckList): number => {
        if (!checklist.sections) {
            return 0;
        }

        let sum = 0;

        for (let i = 0; i < checklist.sections.length; i++) {
            sum += checklist.sections[i].questions.length;
        }

        return sum;
    }

    return (
        <Pivot>
            {
                props.data.map((checkList) => {
                    return (
                            <PivotItem headerText={checkList.name} itemCount={getTotalQuestionsCount(checkList)} itemIcon="WorkItem">
                                <SectionsView
                                    data={checkList.sections || []}
                                    iframeRef={props.iframeRef}
                                    currentItemIndex={props.currentItemIndex}
                                />
                            </PivotItem>
                        )
                })
            }
        </Pivot>
    )
}