import { ChatBubble, ChatBubbleOrientation } from "../../../../components/ChatBubble/ChatBubble";
import { ChatMessage } from "../../../../models/chatMessage";



interface IChatViewProps {
    data: ChatMessage[]
    remoteInspectorName: string
    onfieldEngineerName: string
}

export const ChatView: React.FC<IChatViewProps> = (props) => {

    return (
        <>   <br></br>

            {
                props.data.map(message => {
                    return (
                        <ChatBubble
                            position={message.user === props.remoteInspectorName ? ChatBubbleOrientation.LEFT : ChatBubbleOrientation.RIGHT}
                            text={message.text}
                            chatUserName={message.user}
                        />)
                })
            }
        </>
    )
}
