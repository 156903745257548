export const FacetsNames = {
  locationFacetName: "location",
  onFieldEngineerFacetName: "onFieldEngineer",
  inspectorFacetName: "inspector",
};

export const AdvancedFiltersFacetsNames = {
  countryFacetName: "country",
  companyCodeFacetName: "companyCode",
  checklistNamesFacetName: "checklists/name",
  sectionNamesFacetName: "checklists/sections/name",
  sectionQuestionsFacetName: "checklists/sections/questions/question",
  sectionAnswersFacetName: "checklists/sections/questions/answer",
};
